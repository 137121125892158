<template>
  <base-section id="news">
    <base-section-heading title="Latest News">
      Microsoft’s Women in Cloud Digital Summit to be held on the 28 – 30 Jan 2021 will feature ScrumOnDemand.
    </base-section-heading>

    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="4"
        />
        <v-col
          v-for="(news, i) in articles"
          :key="i"
          cols="12"
          md="4"
        >
          <news-card
            v-bind="news"
            :src="require(`@/assets/${news.image}`)"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        />
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionNews',

    components: {
      NewsCard: () => import('@/components/news/Card'),
    },

    data: () => ({
      articles: [
        {
          icon: 'mdi-play',
          date: 'Jan 15, 2020',
          // category: 'Design',
          // comments: 5,
          title: 'Overview of ScrumOnDemand',
          text: 'Click to listen to an overview of ScrumOnDemand as presented at the summit.',
          image: 'article-sod-wic.png',
        },
        // {
        //   icon: 'mdi-play',
        //   date: 'Oct 19, 2019',
        //   category: 'Strategy',
        //   comments: 8,
        //   title: 'Lorem ipsum dolor, sit amet consectetur',
        //   text: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tenetur, nulla voluptas sed[...]',
        // },
        // {
        //   icon: 'mdi-text',
        //   date: 'Jul 24, 2019',
        //   category: 'Business',
        //   comments: 13,
        //   title: 'Lorem ipsum dolor, sit amet consectetur',
        //   text: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tenetur, nulla voluptas sed[...]',
        // },
      ],
    }),
  }
</script>
